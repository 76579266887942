
import Vue from "vue";
import businessintelligence from "../assets/businessintelligence.png";
import management from "../assets/management.png";
import paperplane from "../assets/paper-plane.png";
import OnneHomeCardItem from "@/components/OnneHomeCardItem.vue";

export default Vue.extend({
  components: { OnneHomeCardItem },
  data: () => ({
    textotitulo: [
      "Soluções Tecnológicas",
      "Bussines Inteligence",
      "Desenvolvimento",
    ],
    cards: [
      {
        titulo: "TechOnne",
        subtitulo: "Support",
        imagem: management,
        apresentacao:
          "Suporte e manutenção de computadores, notebooks e videogames.",
        itens: [
          {
            titulo: "Soluções para Softwares",
            texto:
              "Backup de arquivos, configurações e instalações de programas.",
          },
          {
            titulo: "Soluções para Hardware",
            texto: "Substituição e upgrade de peças.",
          },
          {
            titulo: "Soluções para Sistemas Operacionais",
            texto: "Formatação e reinstalação de sistemas Windows e Linux.",
          },
          {
            titulo: "Soluções para Videogames",
            texto: "Limpeza, manutenção e substituição de peças",
          },
        ],
      },
      {
        titulo: "TechOnne",
        subtitulo: "Insights",
        imagem: businessintelligence,
        apresentacao:
          "Visualize os dados da sua empresa de forma a gerarem valor, transformando-os em informação.",
        itens: [
          {
            titulo: "Desenvolvimento",
            texto:
              "Criação de sofisticadas analises sobre dados apartir do desenvolvimento de processos de ETL.",
          },
          {
            titulo: "Consultoria",
            texto:
              "Receba dicas de como utilizar as ferramentas para alavancar ainda mais seu negócio.",
          },
          {
            titulo: "Suporte",
            texto:
              "Acompanhamento de resultados e necessidades de cada cliente de forma proativa.",
          },
        ],
      },
      {
        titulo: "TechOnne",
        subtitulo: "Fly",
        imagem: paperplane,
        apresentacao:
          "Imagens e videos aéreas de alta resolução para seu negócio ou eventos.",
        itens: [
          {
            titulo: "Publicidade",
            texto: "Videos e fotos aéreas para empresa.",
          },
          {
            titulo: "Inspeções",
            texto: "Realize o acompanhamento e inspeções rápidas e seguras.",
          },
          {
            titulo: "Eventos",
            texto: "Cobertura de eventos.",
          },
          {
            titulo: "Modelagem 3D",
            texto: "Criação de modelos 3D com drone",
          },
        ],
      },
    ],
  }),
});
